@import 'base.app.styles.scss';

fox-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body {
  margin: 0;
}

router-outlet {
  display: none;
}
