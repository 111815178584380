@import './bootstrap/bootstrap-grid';
@import './variables';
@import './elevation';
@import './utilities';
@import './base.component.styles';

body {
  margin: 0;
  font-family: var(--fox-font-family);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: var(--fox-font-size-base);
  font-weight: var(--fox-font-weight-base);
  line-height: var(--fox-line-height-base);
  color: var(--fox-font-base-color);
  text-align: left;
  background-color: var(--fox-feedback-white-color);
  letter-spacing: var(--fox-letter-spacing-base);
  text-decoration: inherit;
  text-transform: inherit;
  background: var(--fox-base-grey-20-color);
}

html {
  --mdc-icon-font: 'Material Icons Round', 'Material Icons';
}

.basic-component-column {
  display: flex;
  flex-direction: column;
}

.border-bottom {
  border-bottom: 1px solid var(--fox-base-grey-30-color);
}

.border-top {
  border-top: 1px solid var(--fox-base-grey-30-color);
}
