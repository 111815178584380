h1,
.h1 {
  font-size: var(--fox-font-size-xxl);
  font-weight: var(--fox-font-weight-bold);
  text-decoration: inherit;
  text-transform: inherit;
  letter-spacing: 0;
}

h2,
.h2 {
  font-size: var(--fox-font-size-xl);
  font-weight: var(--fox-font-weight-bold);
  text-decoration: inherit;
  text-transform: inherit;
  letter-spacing: 0;
}

h3,
.h3 {
  font-size: var(--fox-font-size-l);
  font-weight: var(--fox-font-weight-bold);
  text-decoration: inherit;
  text-transform: inherit;
  letter-spacing: 0;
}

h4,
.h4 {
  font-size: var(--fox-font-size-m);
  font-weight: var(--fox-font-weight-bold);
  text-decoration: inherit;
  text-transform: inherit;
  letter-spacing: 0;
}

h5,
.h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: inherit;
  text-transform: inherit;
  color: red;
}

h6,
.h6 {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0;
  text-decoration: inherit;
  text-transform: inherit;
  color: red;
}

.muted {
  opacity: 0.8;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: var(--fox-font-family);
}

p {
  font-family: var(--fox-font-family);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: var(--fox-font-size-base);
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
}

.fox-font-weight-regular,
.regular {
  font-weight: var(--fox-font-weight-regular, 400) !important;
}
.fox-font-weight-medium,
.medium {
  font-weight: var(--fox-font-weight-medium, 600) !important;
}
.fox-font-weight-semi-bold,
.semi-bold {
  font-weight: var(--fox-font-weight-medium, 600) !important;
}
.fox-font-weight-bold,
.bold {
  font-weight: var(--fox-font-weight-bold, 700) !important;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

$font-size-map: (
  font-size-xs: 10px,
  font-size-s: 12px,
  font-size-m: 14px,
  font-size-l: 17px,
  font-size-xl: 20px,
  font-size-xxl: 24px,
  font-size-base: var(--fox-font-size-m),
) !default;

:root {
  @each $key, $value in $font-size-map {
    --fox-#{$key}: #{$value};
  }
}
@each $key, $value in $font-size-map {
  .fox-#{$key} {
    font-size: var(--fox-#{$key}) !important;
  }
}

.label {
  font-size: var(--fox-font-size-s, 12px);
  color: var(--fox-font-light-color, #738292);
}
