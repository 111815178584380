@import './mixins';
@import './typography';

:host {
  font-size: var(--fox-font-size-base);
  box-sizing: border-box;
  font-family: var(--fox-font-family);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 12px;
  background-color: var(--fox-scrollbar-color, rgba(173, 181, 183, 0.8));
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
