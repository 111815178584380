// Variables should follow the `$brand-component-state-property-size` formula for
// consistent naming. Ex: --fox-button-link-disabled-color and --fox-modal-content-box-shadow-xs.

//Generates variables and classes for common colors
$colors: (
  brand: (
    100: #e75d43,
    90: #ec7241,
    80: #f18a3f,
    70: #f5a33c,
    60: #fabe3a,
    50: #ffcc48,
    40: #ffd95b,
    30: #ffe372,
    20: #ffed8f,
    10: #fff4b3,
    dark: #ec524b,
    base: #fabe3a,
    light: #fcde46,
    gradient-right: transparent
      linear-gradient(
        52deg,
        var(--fox-brand-base-color) 0%,
        var(--fox-brand-light-color) 100%
      )
      0 0 no-repeat padding-box,
  ),
  client: (
    100: #003959,
    90: #005183,
    80: #0f69ac,
    70: #2681d6,
    60: #4599ff,
    50: #4da7ff,
    40: #5ab5ff,
    30: #6cc3ff,
    20: #85d1ff,
    10: #a6dfff,
    dark-rgb: '45, 64, 89',
    base-rgb: '69, 153, 255',
    light-rgb: '118, 221, 255',
    dark: #2d4059,
    base: #4599ff,
    light: #76ddff,
    gradient-top: transparent
      linear-gradient(
        180deg,
        var(--fox-client-light-color) 0%,
        var(--fox-client-base-color) 100%
      )
      0 0 no-repeat padding-box,
    gradient-center:
      linear-gradient(
        90deg,
        var(--fox-client-base-color) 0%,
        var(--fox-client-light-color) 56%,
        var(--fox-client-base-color) 100%
      )
      0 0 no-repeat padding-box,
    text-on-bg: #fff,
  ),
  base-grey: (
    100: #0f1310,
    90: #30373d,
    80: #515c67,
    70: #738292,
    60: #94a8bc,
    50: #b0bfcd,
    40: #cbd5df,
    30: #e7ecf0,
    20: #f5f7f9,
    10: #fcfcfd,
    gradient-center: transparent
      linear-gradient(
        90deg,
        var(--fox-base-grey-90-color) 0%,
        var(--fox-base-grey-80-color) 41%,
        var(--fox-base-grey-90-color) 100%
      )
      0 0 no-repeat padding-box,
  ),
  font: (
    base: var(--fox-base-grey-100-color),
    light: var(--fox-base-grey-70-color),
  ),
  feedback: (
    white: #ffffff,
    error: #ce0707,
    success: #079d73,
    warning: #fdc50d,
    info: #204496,
  ),
);

@each $color, $values in $colors {
  /* #{$color} - colors */
  :root {
    @each $key, $value in $values {
      --fox-#{$color}-#{$key}-color: #{$value};
    }
  }

  // generate helper classes for every color
  @each $key, $value in $values {
    .text-#{$color}-#{$key}-color {
      color: var(--fox-#{$color}-#{$key}-color);
    }
    .bg-#{$color}-#{$key}-color {
      background: var(--fox-#{$color}-#{$key}-color);
    }
  }
}

:root {
  /* gradients */
  --fox-client-base-gradient: transparent
    linear-gradient(
      90deg,
      var(--fox-client-base-color) 0%,
      var(--fox-client-light-color) 56%,
      var(--fox-client-base-color) 100%
    )
    0 0 no-repeat padding-box;

  --fox-brand-base-gradient: transparent
    linear-gradient(
      52deg,
      var(--fox-brand-base-color) 0%,
      var(--fox-brand-light-color) 100%
    )
    0 0 no-repeat padding-box;

  /* typo */

  --fox-font-family: Nunito, Roboto, sans-serif;

  --fox-letter-spacing-base: 0;
  --fox-line-height-base: 1.3;

  --fox-border-radius-s: 4px;
  --fox-border-radius-m: 6px;
}

$fonts: (
  size: (
    xs: 10px,
    s: 12px,
    m: 14px,
    l: 18px,
    xl: 20px,
    xxl: 24px,
    base: var(--fox-font-size-m),
  ),
  weight: (
    light: 300,
    regular: 400,
    base: var(--fox-font-weight-regular),
    medium: 600,
    semi-bold: var(--fox-font-weight-medium),
    bold: 700,
  ),
);

@each $font, $values in $fonts {
  :root {
    @each $key, $value in $values {
      --fox-font-#{$font}-#{$key}: #{$value};
    }
  }
  @each $key, $value in $values {
    .fox-font-#{$font}-#{$key} {
      font-#{$font}: var(--fox-font-#{$font}-#{$key}) !important;
    }
  }
}

/* misc */
html {
  --fox-app-bar-height: 62px;
  --fox-app-bar-z-index: 4;
  --fox-base-transition-all: all ease-out 0.4s;
  --fox-base-transition-property: width, display, height;
  --fox-base-transition-duration: 0.4s;
  --fox-base-timing-function: ease-out;
}
